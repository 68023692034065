const header = document.querySelector('.header');

let prevScrollpos = window.pageYOffset;

window.addEventListener('scroll', ( event ) => {
	const currentScrollPos = window.scrollY;
    header.style.setProperty('--transform', `-${currentScrollPos}px`);

	if (prevScrollpos > currentScrollPos) {
        // Scrolling Up
        header.classList.remove('is-scrolled');
	} else {
        // Scrolling Down
		header.classList.add('is-scrolled');

        if ( currentScrollPos < header.clientHeight + 64) {
            header.classList.add('is-absolute');
        } else {
            header.classList.remove('is-absolute');
        }
	}

	prevScrollpos = currentScrollPos;
});
