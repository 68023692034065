<template>
    <div class="search-container">
        <form ref="form" class="search-bar" role="search" method="post" action="/">
            <div class="input-wrapper">
                <input @keyup="queryFinder" type="search" class="search-field" placeholder="Search Products"
                       name="s"
                       title="Search Products"
                       autocomplete="off"
                       v-model="inputField"
                >
                <button type="submit" value="Search" aria-label="Open Search Bar">
                    <svg class="svg svg-magnifying-glass" viewBox="0 0 475.084 475.084">
                        <use xlink:href="#magnifying-glass"></use>
                    </svg>
                </button>
            </div>
        </form>
        <div class="grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3" :class="[showResults ? 'active search-results' : 'search-results']">
            <div v-if="spinner" class="loader"></div>
            <div v-else-if="results.length > 0" v-for="result in paginatedResults" class="card-product js-card-product">
                <SearchItem :result="result"></SearchItem>
            </div>
            <div v-else class="no-result">
                <span>Sorry, no results were found.</span>
            </div>
        </div>
        <div v-if="showDesktopButtons" class="type pagination">
            <a v-for="btn in paginationBtns" :class="['button pagination', currentPage === btn ? 'primary' : 'hollow']"
               @click="changePage(btn)">{{ btn }}</a>
        </div>
        <div v-else-if="showMobileButtons" class="type pagination mobile">
            <a :class="['button pagination', currentPage === 1 ? 'hollow disabled' : 'primary']"
               @click="changePage('prev')">Prev</a>
            <a :class="['button pagination', currentPage === paginationBtns ? 'hollow disabled' : 'primary']"
               @click="changePage('next')">Next</a>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import SearchItem from "./SearchItem";

export default {
    name: 'search-page',

    components: {SearchItem},

    props: {
        endpoint: {
            type: String,
            default: '/wp-json/api/v1/power-search'
        },
    },

    data() {
        return {
            inputField: searchData ? searchData.term : '',
            showResults: false,
            results: [],
            spinner: false,
            pagination: 15, // <=== This will change the amount of items shown on the page
            currentPage: 1,
            innerWidth: window.innerWidth
        }
    },

    computed: {
        paginationBtns() {
            // If the list of results is less than pagination, show zero buttons
            if (this.results.length <= this.pagination) {
                return 0;
            }
            let buttons = 1;
            let counter = 0;
            // Loop through results, if index is greater than
            this.results.forEach((result, index) => {
                if (index > this.pagination) {
                    if (index % this.pagination === 0) {
                        buttons++;
                        counter = 0;
                    }
                }
            });
            return buttons
        },
        paginatedResults() {
            // if results length is greater than 15, slice array to show only 15 where index of the current page * pagination
            if (this.results.length > 15) {
                if (this.currentPage === 1) {
                    return this.results.slice(0, this.pagination);
                } else {
                    return this.results.slice(this.pagination * this.currentPage, this.pagination * this.currentPage + this.pagination)
                }
            }

            return this.results;
        },
        showMobileButtons(){
            return !this.spinner && this.innerWidth < 1024 && this.results.length > this.pagination ? true : false;
        },
        showDesktopButtons(){
            return !this.spinner && this.innerWidth >= 1024 ? true : false;
        }
    },

    mounted() {
        jQuery("#search-bar-preload").remove();
        console.log("Search Page mounted!");

        // Handle Mobile Pagination Buttons
        window.addEventListener('resize', () => {
            this.innerWidth = window.innerWidth;
        })

        const queryString = window.location.search;
        const urlParams   = new URLSearchParams(queryString);

        if ( urlParams.has('s') && urlParams.get('s').length > 3 ) {
            this.queryFinder('', urlParams.get('s'));
        } else {
            window.history.replaceState(null, null, `?s=${this.inputField}`)
            this.queryFinder(this.inputField)
        }
    },

    methods: {
        queryFinder(e, searchTerm = null) {
            const URL = `${this.endpoint}?s=${searchTerm ? searchTerm : this.inputField.toLowerCase()}`;
            this.spinner = true;
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                axios.get(URL).then(res => {
                    this.results = res.data;
                    this.spinner = false;
                    this.currentPage = 1;
                    localStorage.setItem('results', JSON.stringify(res.data));
                }).catch(err => {
                    console.log(err);
                })
            }, 800);
        },
        changePage(btn) {
            // Mobile Change Page Handler
            if (!Number.isInteger(btn)) {
                if (btn === 'prev' && this.currentPage !== 1) {
                    this.currentPage = this.currentPage - 1
                }
                if(btn === 'next' && this.currentPage !== this.paginationBtns){
                    this.currentPage = this.currentPage + 1;
                }
            } else {
                // Larger Screen Page Handler
                this.currentPage = btn;
            }
        }


    },
}
</script>
