<template>
  <div v-if="enabled" class="loader">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    enabled() {
      return this.show
    }
  }
}
</script>