<template>
	<div class="card__inner">
        <div class="card__link">
            <a :href="result.url" :aria-label="result.title"></a>
        </div>

        <div class="card__favorite" v-if="isLoggedIn && result.wishlist_html" v-html="result.wishlist_html"></div><!-- /.card__favorite -->

		<figure class="card__image">
			<img :src="result.featured_image_src ? result.featured_image_src : defaultImage">
		</figure><!-- /.card__image -->

		<div class="card__content">
			<div class="card__content-main">
				<div class="card__data" v-if="result.compatibility && result.reusability && result.pack_size">
					<ul>
                        <li v-if="result.compatibility">
                            {{ result.compatibility }}
                        </li>

                        <li v-if="result.reusability" style="background-color: #87B7CE;">
                            {{ result.reusability }}
                        </li>

                        <li v-if="result.pack_size" style="background-color: #E9F2F6;">
                            {{ result.pack_size }}
                        </li>
					</ul>
				</div><!-- /.card__data -->

				<div class="card__title">
					<h3 v-html="result.title"></h3>
				</div><!-- /.card__title -->

				<div class="card__info">
					<ul>
                        <li v-if="result.sku">
                            <p>
                                SKU:

                                <span>
                                    {{ result.sku }}
                                </span>
                            </p>
                        </li>

                        <li v-if="result.manufacturer_part">
                            <p>
                                Manufacturer Part #:

                                <span>
                                    {{ result.manufacturer_part }}
                                </span>
                            </p>
                        </li>
					</ul>
				</div><!-- /.card__info -->
			</div><!-- /.card__content-main -->

			<div class="card__content-aside">
				<div class="card__excerpt" v-if="result.description">
					<p>
						{{ result.description }}
					</p>
				</div><!-- /.card__excerpt -->

				<div class="card__price" v-if="isLoggedIn && (result.regular_price || result.sale_price)">
					<ul>
                        <li v-if="result.regular_price && ! result.sale_price">
							<div class="card__price-new">
								<p>${{ result.regular_price }}</p>
							</div><!-- /.card__price-new -->
						</li>

						<li v-if="result.sale_price">
							<div class="card__price-new">
								<p>${{ result.sale_price }}</p>
							</div><!-- /.card__price-new -->
						</li>

                        <li v-if="result.regular_price && result.sale_price">
                            <div class="card__price-old">
                                <p>
                                    <del>${{ result.regular_price }}</del>
                                </p>
                            </div><!-- /.card__price-old -->
                        </li>
					</ul>
				</div><!-- /.card__price -->
			</div><!-- /.card__content-aside -->
		</div><!-- /.card__content -->
	</div><!-- /.card__inner -->

	<div class="card__actions">
        <a class="card__count" v-if="isLoggedIn && !outOfStock">
            <div class="card__count-up js-count-up" @click.prevent="updateQuantity('add')"></div><!-- /.card__count-up -->

            <div class="card__count-down js-count-down" @click.prevent="updateQuantity('remove')"></div><!-- /.card__count-down -->

            <input class='js-count' type="number" :value="quantity" :placeholder="quantity">
        </a><!-- /.card__count -->

		<a
            v-if="isLoggedIn && !outOfStock"
            class="tinvwl-button alt button product_type_simple add_to_cart_button"
            :href="`/cart/?add-to-cart=${result.id}&quantity=${quantity}`"
            :data-product_sku="result.sku"
            :data-product_id="result.id"
            :data-quantity="quantity"
        >
            Add to Cart
        </a>
		<a
            v-else-if="!isLoggedIn"
            class="tinvwl-button alt button product_type_simple add_to_cart_button"
            :href="result.url"
            :data-product_sku="result.sku"
            :data-product_id="result.id"
            :data-quantity="quantity"
        >
            View Product
        </a>
        <button v-else
                class="tinvwl-button alt button product_type_simple add_to_cart_button"
                :disabled="true">Out of Stock</button>
	</div><!-- /.card__actions -->
</template>


<script>
import defaultImage from '../../../images/thumbnail-150.png'

export default {
    name: 'search-item',
    props: {
        result: {
            type: Object
        },
        maxDisplayModels: {
            type: Number,
            default: 3
        },
        placeholderImage: {
            type: String,
            default: '/app/themes/endocorp/assets/images/product-image-coming-soon.png'
        },
    },
    data() {
        return {
            data: '',
            isLoggedIn: false,
            defaultImage: this.placeholderImage || defaultImage,
            quantity: 1,
        }
    },
    computed: {
        hasModels() {
            return this.result.hasOwnProperty('models') && !!this.result.models && this.result.models.length > 0;
        },
        modelsString() {
            if (!this.hasModels) {
                return '';
            }
            return this.result.models;
        },
        models() {
            if (!this.hasModels) {
                return [];
            }
            return this.modelsString.split(', ');
        },
        modelCount() {
            return this.models.length;
        },
        shouldUseLightbox() {
            return this.modelCount > this.maxDisplayModels;
        },
        modelsPreviewString() {
            if (!this.hasModels) {
                return '';
            }
            let previewModels = this.models.slice(0, this.maxDisplayModels).join(', ');
            return `${previewModels}, ...`;
        },
        outOfStock() {
            return this.result.hasOwnProperty('stock_status') && this.result.stock_status === 'outofstock';
        }
    },
    mounted() {
        document.body.classList.contains('logged-in') ? this.isLoggedIn = true : this.isLoggedIn = false;
    },
    methods: {
        updateQuantity(method) {
            if ( method == 'add' ) {
                this.quantity = this.quantity + 1;
            } else if ( method == 'remove' && this.quantity > 1 ) {
                this.quantity = this.quantity - 1;
            }
        }
    }

}
</script>
