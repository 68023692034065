document.querySelectorAll('.menu-item-has-children > a').forEach(function(anchor) {
    anchor.addEventListener('click', function(event) {
        const submenu = anchor.querySelector('.sub-menu');

        if (submenu.style.display !== 'block' || anchor.classList.contains('js-submenu-expand')) {
            event.preventDefault();
        }

        if (anchor.classList.contains('js-submenu-expand')) {
            toggleSlide(submenu, 400);
            anchor.parentElement.classList.toggle('is-open');

            anchor.parentElement.querySelectorAll('.is-open').forEach(function(openItem) {
                openItem.classList.remove('is-open');
                const childSubmenu = openItem.querySelector('.sub-menu');
                if (childSubmenu) {
                    slideUp(childSubmenu, 400);
                }
            });
        } else {
            slideDown(submenu, 400);
            anchor.parentElement.classList.add('is-open');
        }

        anchor.parentElement.parentElement.querySelectorAll('li').forEach(function(sibling) {
            if (sibling !== anchor.parentElement) {
                sibling.classList.remove('is-open');
                const siblingSubmenu = sibling.querySelector('.sub-menu');
                if (siblingSubmenu) {
                    slideUp(siblingSubmenu, 400);
                }
            }
        });

        if (window.matchMedia('(max-width: 1023px)').matches) {
            anchor.closest('.header__nav-inner').querySelectorAll('.js-has-children.is-open').forEach(function(openItem) {
                openItem.classList.remove('is-open');
                const jsSubmenu = openItem.querySelector('.js-submenu');
                if (jsSubmenu) {
                    slideUp(jsSubmenu, 400);
                }
            });
        }
    });
});

document.querySelectorAll('.js-has-children > a').forEach(function(anchor) {
    anchor.addEventListener('click', function(event) {
        if (window.matchMedia('(max-width: 1023px)').matches) {
            const submenu = anchor.nextElementSibling;

            if (submenu.style.display !== 'block' || anchor.classList.contains('js-submenu-expand')) {
                event.preventDefault();
            }

            if (anchor.classList.contains('js-submenu-expand')) {
                toggleSlide(submenu, 400);
                anchor.parentElement.classList.toggle('is-open');

                anchor.parentElement.querySelectorAll('.is-open').forEach(function(openItem) {
                    openItem.classList.remove('is-open');
                    const childSubmenu = openItem.querySelector('.js-submenu');
                    if (childSubmenu) {
                        slideUp(childSubmenu, 400);
                    }
                });
            } else {
                slideDown(submenu, 400);
                anchor.parentElement.classList.add('is-open');
            }

            anchor.parentElement.parentElement.querySelectorAll('li').forEach(function(sibling) {
                if (sibling !== anchor.parentElement) {
                    sibling.classList.remove('is-open');
                    const siblingSubmenu = sibling.querySelector('.js-submenu');
                    if (siblingSubmenu) {
                        slideUp(siblingSubmenu, 400);
                    }
                }
            });

            anchor.closest('.header__nav-inner').querySelectorAll('.nav .is-open').forEach(function(openItem) {
                openItem.classList.remove('is-open');
                const subMenu = openItem.querySelector('.sub-menu');
                if (subMenu) {
                    slideUp(subMenu, 400);
                }
            });
        }
    });
});

// Helper functions for slide up, slide down, and toggle slide
function slideUp(element, duration) {
    element.style.height = element.offsetHeight + 'px';
    element.style.transitionProperty = 'height, margin, padding';
    element.style.transitionDuration = duration + 'ms';
    element.style.height = 0;
    element.style.paddingTop = 0;
    element.style.paddingBottom = 0;
    element.style.marginTop = 0;
    element.style.marginBottom = 0;

    setTimeout(() => {
        element.style.display = 'none';
        element.style.removeProperty('height');
        element.style.removeProperty('padding-top');
        element.style.removeProperty('padding-bottom');
        element.style.removeProperty('margin-top');
        element.style.removeProperty('margin-bottom');
        element.style.removeProperty('transition-duration');
        element.style.removeProperty('transition-property');
    }, duration);
}

function slideDown(element, duration) {
    element.style.removeProperty('display');
    let display = window.getComputedStyle(element).display;

    if (display === 'none') display = 'block';

    element.style.display = display;
    let height = element.offsetHeight;
    element.style.height = 0;
    element.style.paddingTop = 0;
    element.style.paddingBottom = 0;
    element.style.marginTop = 0;
    element.style.marginBottom = 0;
    element.style.transitionProperty = 'height, margin, padding';
    element.style.transitionDuration = duration + 'ms';
    element.style.height = height + 'px';
    element.style.removeProperty('padding-top');
    element.style.removeProperty('padding-bottom');
    element.style.removeProperty('margin-top');
    element.style.removeProperty('margin-bottom');

    setTimeout(() => {
        element.style.removeProperty('height');
        element.style.removeProperty('transition-duration');
        element.style.removeProperty('transition-property');
    }, duration);
}

function toggleSlide(element, duration) {
    if (window.getComputedStyle(element).display === 'none') {
        return slideDown(element, duration);
    } else {
        return slideUp(element, duration);
    }
}
