import Swiper, { Navigation, Autoplay } from 'swiper';

function destroySlider(slider) {
    if (slider === undefined) return;

    slider.destroy();
    slider = undefined;
}

// Function to run on window load
window.addEventListener('load', function () {

    // Vanilla JS equivalent of $(...).each
    document.querySelectorAll('.js-slider-products').forEach((el) => {
        let productsSlider;

        if (productsSlider !== undefined) return;

        productsSlider = new Swiper(el, {
            modules: [Navigation],
            centeredSlides: false,
            loop: true,
            navigation: {
                prevEl: el.parentNode.querySelector('.swiper-button-prev'),
                nextEl: el.parentNode.querySelector('.swiper-button-next'),
            },
            breakpoints: {
                320: { slidesPerView: 1, spaceBetween: 14 },
                768: { slidesPerView: 2, spaceBetween: 14 },
                1200: { slidesPerView: 3, spaceBetween: 28 },
                1400: { slidesPerView: 4, spaceBetween: 28 }
            }
        });
    });

    document.querySelectorAll('.js-slider-products-nav').forEach((el) => {
        let slider;

        function buildSlider() {
            if (slider !== undefined) return;

            slider = new Swiper(el, {
                centeredSlides: false,
                loop: false,
                slidesPerView: 'auto',
                touchMoveStopPropagation: true,
            });
        }

        function handleSlider() {
            if (window.matchMedia('(max-width: 1023px)').matches) {
                destroySlider();
            } else {
                buildSlider();
            }
        }

        window.addEventListener('resize', handleSlider);
        handleSlider();  // Run initially on load
    });

    document.querySelectorAll('.js-slider-products-nav-secondary').forEach((el) => {
        let slider;

        function buildSlider() {
            if (slider !== undefined) return;

            slider = new Swiper(el, {
                centeredSlides: false,
                loop: false,
                slidesPerView: 'auto',
                breakpoints: {
                    320: {
                        spaceBetween: 16,
                        slidesOffsetAfter: 14,
                        slidesOffsetBefore: 14,
                    },
                    1200: {
                        spaceBetween: 16,
                        slidesOffsetAfter: 16,
                        slidesOffsetBefore: 16,
                    },
                    1400: {
                        spaceBetween: 24,
                        slidesOffsetAfter: 24,
                        slidesOffsetBefore: 24,
                    }
                }
            });
        }

        function handleSlider() {
            if (window.matchMedia('(max-width: 1023px)').matches) {
                destroySlider();
            } else {
                buildSlider();
            }
        }

        window.addEventListener('resize', handleSlider);
        handleSlider();  // Run initially on load
    });

    document.querySelectorAll('.js-slider-announcement').forEach((el) => {
        let slider;

        function buildSlider() {
            if (slider !== undefined) return;

            slider = new Swiper(el, {
                modules: [Autoplay],
                centeredSlides: false,
                loop: true,
                autoplay: {
                    delay: 2000,
                },
                slidesPerView: 1,
                spaceBetween: 16,
            });
        }

        function handleSlider() {
            if (window.matchMedia('(max-width: 767px)').matches) {
                buildSlider();
            } else {
                destroySlider();
            }
        }

        window.addEventListener('resize', handleSlider);
        handleSlider();  // Run initially on load
    });

    document.querySelectorAll('.js-slider-categories').forEach((el) => {
        let slider;

        function buildSlider() {
            if (slider !== undefined) return;

            slider = new Swiper(el, {
                modules: [Autoplay],
                centeredSlides: true,
                loop: true,
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: false,
                },
                breakpoints: {
                    320: {
                        slidesPerView: 1.5,
                        spaceBetween: 32,
                    },
                    768: {
                        slidesPerView: 2.5,
                        spaceBetween: 32,
                    }
                }
            });
        }

        function handleSlider() {
            if (window.matchMedia('(max-width: 1023px)').matches) {
                buildSlider();
            } else {
                destroySlider();
            }
        }

        window.addEventListener('resize', handleSlider);
        handleSlider();  // Run initially on load
    });

});
