import Swiper from 'swiper';

const tabs = document.querySelector('.wc-tabs');

const setTabs = () => {
	if ( ! tabs ) {
		return;
	}

	const newNode = document.createElement('div');
	const slides = tabs.querySelectorAll('li');

	newNode.classList.add('tabs-wrapper', 'swiper', 'js-slider-tabs');

	tabs.classList.add('swiper-wrapper');

	slides.forEach(slide => {
		slide.classList.add('swiper-slide');
	});

	tabs.parentNode.insertBefore(newNode, tabs);
	newNode.appendChild(tabs);

	let slider;

	function buildSlider() {
		if (slider !== undefined) {
			return;
		}

		slider = new Swiper(newNode, {
			slidesPerView: 'auto',
		});
	}

	function destroySlider() {
		if (slider === undefined) {
			return;
		}

		slider.destroy();
		slider = undefined;
	}

	function handleSlider() {
		if ( window.matchMedia('(min-width: 768px)').matches ) {
			buildSlider();
		} else {
			destroySlider();
		}
	}

	window.addEventListener('load', handleSlider);
	window.addEventListener('resize', handleSlider);
};

window.addEventListener('load', setTabs);
