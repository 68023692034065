// Vanilla JS for handling click event on product navigation links
document.querySelectorAll('.js-products-nav > ul > li > a').forEach((anchor) => {
    anchor.addEventListener('click', function(event) {
        const id = anchor.getAttribute('data-id');
        const parentLi = anchor.parentElement;

        if (!window.matchMedia('(max-width: 1023px)').matches) {
            if (!parentLi.classList.contains('is-active')) {
                event.preventDefault();
            }

            const targetElement = document.getElementById(id);

            if (targetElement) {
                targetElement.classList.add('is-active');
                [...targetElement.parentElement.children].forEach(sibling => {
                    if (sibling !== targetElement) sibling.classList.remove('is-active');
                });
            } else {
                document.querySelectorAll('.header__navigations-secondary.is-active')
                    .forEach(elem => elem.classList.remove('is-active'));
            }

            parentLi.classList.add('is-active');
            [...parentLi.parentElement.children].forEach(sibling => {
                if (sibling !== parentLi) sibling.classList.remove('is-active');
            });
        }
    });
});

// Move products nav between mobile and desktop containers based on viewport size
const desktop_parent = document.querySelector('.header__navigations > .shell');
const mobile_parent = document.querySelector('.header__nav-inner');

const moveProductsNav = () => {
    const products_nav = document.querySelector('.js-products-nav');

    if (window.matchMedia('(max-width: 1023px)').matches) {
        mobile_parent.insertBefore(products_nav, mobile_parent.firstChild);
        products_nav.classList.add('is-moved');
    } else {
        desktop_parent.appendChild(products_nav);
        products_nav.classList.remove('is-moved');
    }
};

window.addEventListener('DOMContentLoaded', moveProductsNav);
window.addEventListener('load', moveProductsNav);
window.addEventListener('resize', moveProductsNav);

/* Mega menu Functionality */
const subcategories = document.querySelectorAll('.header__navigations-secondary > ul > li');

subcategories.forEach(subcategory => {
    let hoveringItem = false;

    const megaMenu = document.getElementById(subcategory.getAttribute('data-id'));

    if(megaMenu === null || megaMenu === undefined || megaMenu === false) {
        return;
    }

    subcategory.addEventListener('mouseover', () => {
        hoveringItem = true;
        megaMenu.classList.add('is-hovered');
        subcategory.classList.add('is-hovered');
    });

    subcategory.addEventListener('mouseout', () => {
        hoveringItem = false;
        megaMenu.classList.remove('is-hovered');
        subcategory.classList.remove('is-hovered');
    });

    megaMenu.addEventListener('mouseover', () => {
        hoveringItem = true;
        megaMenu.classList.add('is-hovered');
        subcategory.classList.add('is-hovered');
    });

    megaMenu.addEventListener('mouseout', () => {
        hoveringItem = false;
        megaMenu.classList.remove('is-hovered');
        subcategory.classList.remove('is-hovered');
    });

    document.addEventListener('mousemove', () => {
        if (!hoveringItem) {
            megaMenu.classList.remove('is-hovered');
            subcategory.classList.remove('is-hovered');
        }
    });
});
