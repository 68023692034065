(function ($) {
    if ( $('.woocommerce-product-gallery').length > 0 ) {
        $.ajax({
            url: '/wp/wp-admin/admin-ajax.php',
            data: {
                action: 'get_product_compatibility_checker',
            },
            success: function(response) {
                if ( response.data.html ) {
                    $('.woocommerce-product-gallery').append( response.data.html );
                }
            }
        });
    }

    $('.woocommerce-product-gallery').on('click', '.js-check-compatibility', function(event){
        event.preventDefault();

        const $this       = $(this);
        const modelNumber = $(this).siblings('.js-compatibility-input').val();
        const productID   = $('.header').attr('data-page');

        if ( modelNumber.length == 0 ) {
            $this.parent().siblings('.product-compatibility__status').find('.compatible').hide();
            $this.parent().siblings('.product-compatibility__status').find('.not-compatible').hide();

            return;
        }

        $.ajax({
            url: '/wp/wp-admin/admin-ajax.php',
            data: {
                action: 'check_product_compatibility',
                modelNumber: modelNumber,
                productID: productID
            },
            success: function(response) {
                if ( response.data.compatible ) {
                    $this.parent().siblings('.product-compatibility__status').find('.compatible').show();
                    $this.parent().siblings('.product-compatibility__status').find('.not-compatible').hide();
                } else {
                    $this.parent().siblings('.product-compatibility__status').find('.compatible').hide();
                    $this.parent().siblings('.product-compatibility__status').find('.not-compatible').show();
                }
            }
        });
    });
})(jQuery); // Fully reference jQuery after this point.