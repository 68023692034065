document.addEventListener('DOMContentLoaded', function(){
    const copyBtn = document.getElementById('copy-from-billing');
    const billingPhone = document.getElementById('reg_billing_phone');
    const billingAddress1 = document.getElementById('reg_billing_address_1');
    const billingAddress2 = document.getElementById('reg_billing_address_2');
    const billingCity = document.getElementById('reg_billing_city');
    const billingState = document.getElementById('reg_billing_state');
    const billingZip = document.getElementById('reg_billing_postcode');
    const billingCountry = document.getElementById('reg_billing_country');

    const shippingPhone = document.getElementById('reg_shipping_phone');
    const shippingAddress1 = document.getElementById('reg_shipping_address_1');
    const shippingAddress2 = document.getElementById('reg_shipping_address_2');
    const shippingCity = document.getElementById('reg_shipping_city');
    const shippingState = document.getElementById('reg_shipping_state');
    const shippingZip = document.getElementById('reg_shipping_postcode');
    const shippingCountry = document.getElementById('reg_shipping_country');

    if(copyBtn){
        copyBtn.addEventListener('click', function(){
            shippingPhone.value = billingPhone.value;
            shippingAddress1.value = billingAddress1.value;
            shippingAddress2.value = billingAddress2.value;
            shippingCity.value = billingCity.value;
            shippingState.value = billingState.value;
            shippingZip.value = billingZip.value;
            shippingCountry.value = billingCountry.value;
        });
    }
});
