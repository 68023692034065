require('./routing');

import {createApp} from "vue";

import components from '@/components/global';

const main = createApp({
    mounted() {
        // using jQuery is necessary for compatibility with GravityForms
        jQuery(document).bind('gform_confirmation_loaded', function (event, formId) {
            window.dataLayer.push({
                'event': 'gravity_form_submission',
                'gf_form_id': formId
            });
        });
    }
});

for (const [name, component] of Object.entries(components)) {
    main.component(name, component);
}

main.config.isCustomElement = tag => tag.startsWith('wp-');
const el = document.getElementById('app');
main.mount(el);
