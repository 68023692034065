(function () {
    let dbMessage;

    let regError = document.querySelector(".woocommerce-error li");
    if (regError !== null) {
        dbMessage = tmprod_registration_confirmation_message.message;
        if (dbMessage.length <= 0) {
            return;
        }

        let uiMessage = regError.textContent;

        uiMessageParts = uiMessage.split("Error: ");
        uiMessageEnd = uiMessageParts[1];

        if (!uiMessageEnd) {
            return;
        }
        uiMessageEndParts = uiMessageEnd.split(" ");
        dbMessageParts = dbMessage.split(" ");

        let included = 0;
        for (let i = 0; i < uiMessageEndParts.length; i++) {
            if (dbMessageParts.includes(uiMessageEndParts[i])) {
                included++;
            }
        }
        if (included / dbMessageParts.length < .8) {
            return;
        }

        let parts = uiMessage.trim().split("Error:");
        regError.textContent = parts[1].trim();

        let heads = document.getElementsByTagName("HEAD");
        let head = heads[0];
        let style = document.createElement("STYLE");
        style.type = "text/css";

        style.appendChild(document.createTextNode(".woocommerce-error:before {color: orange !important;}"));
        head.appendChild(style);
    }
})();
