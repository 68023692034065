const products = document.querySelectorAll('.js-card-product');

products.forEach(product => {
	const countUp = product.querySelector('.js-count-up');
	const countDown = product.querySelector('.js-count-down');
	const count = product.querySelector('.js-count');
	const addToCart = product.querySelector('.add_to_cart_button');

	const changeCount = (event, method) => {
		event.preventDefault();

		let newCount = method === 'add' ? count.value++ : (count.value > 1 ? count.value-- : count.value = 1);

		addToCart.setAttribute('data-quantity', count.value);

		return newCount;
	};

	countUp.addEventListener('click', (event) => changeCount(event, 'add'));
	countDown.addEventListener('click', (event) => changeCount(event, 'remove'));
});
