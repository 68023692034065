(function ($) {
    /**
     * Initialize Product Card Carousel
     */
    const featuredProductCards = $('#featured-product-cards');
    if (featuredProductCards.length > 0) {
        if (featuredProductCards.find('.card').length > 3) {
                $(featuredProductCards).owlCarousel({
                    stagePadding: 20,
                    margin: 40,
                    items: 3,
                    nav: false,
                    loop: true,
                    responsive: {
                        0 : {
                            items: 1,
                        },
                        600 : {
                            items: 2,
                        },
                        992 : {
                            items: 3,
                        },
                    },
                    autoplay: 1500,
                });
        }
    }
})(jQuery); // Fully reference jQuery after this point.
