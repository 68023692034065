const header = document.querySelector('.header');
const nav = document.querySelector('.header__nav');
const header_announcement = document.querySelector('.header__announcement');
const header_main = document.querySelector('.header__main');
const header__navigations = document.querySelector('.header__navigations');
const nav_actions = document.querySelector('.header__nav-actions');
const main = document.querySelector('main');

const setNavHeight = () => {
	header.style.setProperty('--nav-header-height', `${header_announcement.clientHeight + header_main.clientHeight}px`);
	nav.style.setProperty('--nav-height', `${header_announcement.clientHeight + header_main.clientHeight + nav_actions.clientHeight}px`);

	if (window.matchMedia('(max-width: 1023px)').matches) {
		main.style.setProperty('--header-height', `${header_announcement.clientHeight + header_main.clientHeight - 2}px`);
	} else {
		main.style.setProperty('--header-height', `${header_announcement.clientHeight + header_main.clientHeight + header__navigations.clientHeight + 62}px`);
	}
};

window.addEventListener('DOMContentLoaded', setNavHeight);
window.addEventListener('load', setNavHeight);
window.addEventListener('resize', setNavHeight);
