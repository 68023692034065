<template>
    <div :class="[showResults ? 'active search-container' : 'search-container']" @click="onClick">
        <form ref="form" class="search-bar" role="search" method="post" action="/">
            <div class="input-wrapper">
                <input @keyup="queryFinder" @focus='onFocus' @input="inputChange" type="search" class="search-field" :placeholder="changePlaceholder"
                       name="s"
                       title="Search Products"
                       autocomplete="off"
                       v-model="inputField"
                >
                <button type="submit" value="Search" aria-label="Search">
                    <svg class="svg svg-magnifying-glass" viewBox="0 0 475.084 475.084">
                        <use xlink:href="#magnifying-glass"></use>
                    </svg>
                </button>
            </div>
        </form>
        <div class="results-wrapper" tabindex="0">
            <div v-if="spinner" class="loader"></div>
            <div v-else class="results">

                <div class="results__products">
                    <ul v-if="results.length > 0">
                        <li v-for="result in results">
                            <a class="result-item" :href="result.url">
                                <img :src="result.featured_image_src ? result.featured_image_src : defaultImage">

                                <div class="result-content">
                                    <p v-html="result.title"></p>

                                    <p v-if="result.sku" class="sku"><span>SKU#</span> {{ result.sku }}</p>
                                </div>
                            </a>
                        </li>
                    </ul>

                    <ul v-else-if="results.length == 0 && displayedResults.length == 0" class="no-results">
                        <li>Sorry, no results found. Try again.</li>
                    </ul>

                    <ul v-else>
                        <li v-for="result in displayedResults">
                            <a class="result-item" :href="result.url">
                                <img :src="result.featured_image_src ? result.featured_image_src : defaultImage">

                                <div class="result-content">
                                    <p v-html="result.title"></p>

                                    <p v-if="result.sku" class="sku"><span>SKU#</span> {{ result.sku }}</p>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div><!-- /.results__products -->
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import defaultImage from '../../../images/thumbnail-150.png'

export default {
    name: 'search-bar',

    props: {
        endpoint: {
            type: String,
            default: '/wp-json/api/v1/power-search'
        },
        placeholderImage: {
            type: String,
            default: '/app/themes/endocorp/assets/images/product-image-coming-soon.png'
        },
        perPage: {
            type: Number,
            default: 12
        },
    },

    data() {
        return {
            inputField: '',
            showResults: false,
            results: [],
            displayedResults: [],
            suggestedCategories: themeVariables.suggestedCategories,
            spinner: false,
            defaultImage: this.placeholderImage || defaultImage,
            innerWidth: window.innerWidth
        }
    },
    computed: {
        changePlaceholder(){
            this.placeholder = ''
            return this.innerWidth >= 576 ?  this.placeholder = 'Search over 2 million OEM Repair Parts by part name, model, or part number' : this.placeholder = "Search";
        }
    },
    methods: {
        queryFinder(e) {
            this.spinner = true;
            const URL = `${this.endpoint}?s=${this.inputField.toLowerCase()}`;
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
            axios.get(URL).then(res => {
                this.results = res.data;
                this.spinner = false;
                localStorage.setItem('results', JSON.stringify(res.data));

                // Populate displayedResults if there are no search results
                if (this.results.length === 0) {
                    this.displayedResults = res.data;
                }
            }).catch(err => {
                console.log(err);
            });
            }, 800);
        },
        onFocus(event) {
            if (event.target.value < 1) {
                this.showResults = false;
            } else {
                this.showResults = true;
            }
        },
        inputChange(event) {
            if (event.target.value < 1) {
                this.showResults = false;
            } else {
                this.showResults = true;
            }
        },
        onClick(event) {
            if ( event.target.classList.contains('search-container')) {
                this.showResults = false;
            }
        },
        decodeHtmlEntities(html) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        },

        fetchInitialResults() {
            axios.get(this.endpoint).then(res => {
                this.displayedResults = res.data.slice(0, this.perPage);
            }).catch(err => {
                console.log(err);
            });
        },
    },
    mounted() {
        jQuery("#search-bar-preload").remove();
        console.log("Search Bar mounted!");

        // Handle Search Placeholder Text
        window.addEventListener('resize', () => {
            this.innerWidth = window.innerWidth;
        });

        window.addEventListener('load', () => {
            if (!window.isSearchPage) {
                // this.fetchInitialResults();
            } else {
                this.queryFinder();
            }
        });
    }
}
</script>
