document.querySelector('.js-nav-trigger').addEventListener('click', function() {
    this.classList.toggle('is-active');
    document.querySelector('.header__nav').classList.toggle('is-active');

    setTimeout(() => {
        const opened = document.querySelector('.header__nav-inner .is-open');
        if (opened) {
            opened.classList.remove('is-open');
            const subMenu = opened.querySelector('.sub-menu');
            if (subMenu) {
                subMenu.style.display = 'none'; // Equivalent to slideUp(0)
            }

            if (window.matchMedia('(max-width: 1023px)').matches) {
                const jsSubmenu = opened.querySelector('.js-submenu');
                if (jsSubmenu) {
                    jsSubmenu.style.display = 'none'; // Equivalent to slideUp(0)
                }
            }
        }
    }, 200);

    document.body.classList.toggle('is-locked');
});

document.querySelector('.js-nav-profile').addEventListener('click', function() {
    if (window.matchMedia('(max-width: 1024px)').matches) {
        const parent = this.parentElement;
        parent.classList.toggle('is-active');
        const navLang = parent.closest('.nav-utils').querySelector('.nav__lang');
        if (navLang) {
            navLang.classList.remove('is-active');
        }
    }
});

document.querySelector('.js-nav-lang').addEventListener('click', function() {
    if (window.matchMedia('(max-width: 1024px)').matches) {
        const parent = this.parentElement;
        parent.classList.toggle('is-active');
        const navProfile = parent.closest('.nav-utils').querySelector('.nav__profile');
        if (navProfile) {
            navProfile.classList.remove('is-active');
        }
    }
});
