/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Namespace contains common and page-specific functions.
    var TMBase = {

        // All pages
        'common': {
            init: function () {

                // Remove html.no-js
                $('html').removeClass('no-js');

                /**
                 * Open all external links in a new tab.
                 *
                 * This adds the ":external" selector to jQuery.
                 * Then queries all external links, tests them, and adds external attributes.
                 */
                $.expr[':'].external = function (obj) {
                    return !obj.href.match(/^mailto:/) &&
                        !obj.href.match(/^tel:/) &&
                        (obj.hostname !== location.hostname) &&
                        !obj.href.match(/^javascript:/) &&
                        !obj.href.match(/^$/);
                };
                $('a:external').attr('target', '_blank').attr('rel', 'external');

                /**
                 * Test the User Agent for Internet Explorer.
                 *
                 * If using Internet Explorer, add ie & ie-{version} classes
                 * to the root html element.
                 */
                (function internetExplorer() {
                    function detectIE() {
                        var ua = window.navigator.userAgent;

                        var msie = ua.indexOf('MSIE ');
                        if (msie > 0) {
                            // IE 10 or older => return version number
                            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
                        }

                        var trident = ua.indexOf('Trident/');
                        if (trident > 0) {
                            // IE 11 => return version number
                            var rv = ua.indexOf('rv:');
                            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
                        }

                        var edge = ua.indexOf('Edge/');
                        if (edge > 0) {
                            // Edge (IE 12+) => return version number
                            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
                        }

                        // other browser
                        return false;
                    }

                    var IEVersion = detectIE();
                    if (IEVersion) {
                        $('html').addClass('ie').addClass('ie-' + IEVersion);
                    }
                })();

                /**
                 * Accordions
                 */
                var accordions = $('.accordion');
                if(accordions.length) {
                    $(accordions).each(function (i) {
                        // Find all the bellows in the accordion.
                        var bellows = $(accordions[i]).find('.bellow');
                        // loop through each of the bellows
                        $(bellows).each(function (b) {
                            var bellowHeader = $(bellows[b]).find('.bellow-header');
                            var bellowBody = $(bellows[b]).find('.bellow-body');
                            // listen for a click on each bellow header
                            bellowHeader.on('click', function () {
                                $(bellows).each(function (c) {
                                    // Check if the current bellow contains the clicked bellow header
                                    if(b !== c){
                                        if (bellows[c].classList.contains('open')) {
                                            $(bellows[c]).find('.bellow-body').slideUp(300);
                                            bellows[c].classList.remove('open');
                                        }
                                        // Check if the clicked bellow is open
                                    } else if (bellows[b].classList.contains('open')) {
                                        bellows[b].classList.remove('open');
                                        bellowBody.slideUp(300);
                                    } else {
                                        bellows[b].classList.add('open');
                                        bellowBody.slideDown(300);
                                    }
                                });

                            });
                        });
                    });
                }


            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },

        // Pages with rotators
        'has_rotator': {
            init: function () {

                /**
                 * Initialize Rotators
                 */
                var rotators = $('.rotator-wrap');
                $(rotators).each(function (i) {
                    var owlEl = $(rotators[i]).find('.rotator'),
                        slides = $(rotators[i]).find('.banner'),
                        prevSlide = $(rotators[i]).find('.rotator-prev'),
                        nextSlide = $(rotators[i]).find('.rotator-next');

                    if (slides.length > 1) {
                        $(owlEl).addClass('owl-carousel').owlCarousel({
                            animateOut: 'fadeOut',
                            items: 1,
                            loop: true,
                            autoplay: true,
                            autoplayTimeout: 5000,
                            autoplayHoverPause: true,
                        });
                        if (prevSlide.length) {
                            prevSlide.on('click', function () {
                                owlEl.trigger('prev.owl.carousel');
                            });
                        }
                        if (nextSlide.length) {
                            nextSlide.on('click', function () {
                                owlEl.trigger('next.owl.carousel');
                            });
                        }
                    }
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = TMBase;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
