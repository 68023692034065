import ScrollLink from "@/components/navigation/ScrollLink";
import SlideVertical from "@/components/transitions/SlideVertical";
import Accordion from "@/components/accordion/Accordion";
import AccordionItem from "@/components/accordion/AccordionItem";
import SearchBar from "@/components/search/SearchBar";
import SearchPage from "@/components/search/SearchPage";
import ProductFilters from "@/components/filters/ProductFilters";

/**
 * Register global Vue components below and they will be auto-registered
 * with the root instance.
 */
export default {
    'scroll-link': ScrollLink,
    'slide-vertical': SlideVertical,
    'accordion': Accordion,
    'accordion-item': AccordionItem,
    'search-bar': SearchBar,
    'search-page': SearchPage,
    'product-filters': ProductFilters,
};
