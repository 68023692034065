const table = document.querySelector('.the-content');
const tableHeader = document.querySelector('.woocommerce-products-header');

if (table) {
    table.addEventListener('click', function(e) {
        if (e.target.tagName === 'A' && e.target.classList.contains('page-numbers') ) {
            tableHeader.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
    }, true);
}
