/**
 * Initialize Hero Slider
 */
window.addEventListener("load", () => {
    const owlEl = document.querySelector('.hero');
    const slides = document.querySelectorAll('.slide');
    const prevSlide = document.querySelector('.hero-nav-prev');
    const nextSlide = document.querySelector('.hero-nav-next');

    if (slides.length > 1) {
        if (owlEl) {
            jQuery(owlEl).owlCarousel({
                animateOut: 'fadeOut',
                items: 1,
                loop: true
            });

            // Previous slide button functionality
            if (prevSlide) {
                prevSlide.addEventListener('click', function () {
                    jQuery(owlEl).trigger('prev.owl.carousel');
                });
            }

            // Next slide button functionality
            if (nextSlide) {
                nextSlide.addEventListener('click', function () {
                    jQuery(owlEl).trigger('next.owl.carousel');
                });
            }
        }
    }
});
