<template>
    <div class="pa-product-filters">

        <div class="filters-container">

            <div class="category-filter">

                <div class="category-title">
                    Select A Category:
                </div>

                <div class="radio-control" v-for="(category, index) in categoryStates" :key="index">
                    <input type="radio" name="categories" :id="category.name" :value="category.value"
                           v-model="categoryStates[index].value" @change="onCategorySelect(index)"
                           :checked="currentState === category.id"/>
                    <label :for="category.name">{{ category.text }}</label>
                </div>
            </div>

            <form :class="currentState">
                <div class="filters" :class="filterSelectClass">
                    <div class="select-control"
                         v-for="(categorySelect, categoryName, ii) in categorySelects"
                         v-show="categorySelects[categoryName].visible">

                        <div v-show="categoryName === 'manufacturer' && loading['manufacturers']" class="loader" >
                            <div class="spinner"></div>
                        </div>

                        <div v-show="categoryName === 'series' && loading['series']" class="loader" >
                            <div class="spinner"></div>
                        </div>

                        <div v-show="categoryName === 'models' && loading['models']" class="loader">
                            <div class="spinner"></div>
                        </div>

                        <div v-show="categoryName === 'series' && loading['endoscopy-accessories']" class="loader">
                            <div class="spinner"></div>
                        </div>

                        <div v-show="categoryName === 'models' && loading['medical-devices']" class="loader">
                            <div class="spinner"></div>
                        </div>

                        <div v-show="categoryName === 'subcategories' && loading['subcategories']" class="loader">
                            <div class="spinner"></div>
                        </div>

                        <!--            <div v-show="categoryName === 'manufacturer' && loading['equipment']" class="loader">-->
                        <!--              <div class="spinner"></div>-->
                        <!--            </div>-->

                        <div v-show="categoryName === 'manufacturer-part-number' && loading['manufacturer-part-number']" class="loader">
                          <div class="spinner"></div>
                        </div>

                        <select :id="categorySelect.id" :name="categoryName" :key="ii" :ref="categoryName" :aria-label="categoryName"
                                :disabled="categorySelects[categoryName].disabled === true || categorySelect.items.length === 0" v-model="filters[categoryName]"
                                @change="e => onSelectChange(e, categoryName)">
                            <option value="">{{ selectedLabel(categoryName) }}</option>

                            <option v-for="(item, itemKey) in categorySelect.items"
                                    :key="itemKey"
                                    :value="item.name"
                                    v-html="item.text"></option>
                        </select>
                    </div>
                </div>

                <button type="button" class="button tertiary" @click.prevent="filterProducts">SHOP NOW</button>
            </form>

        </div>

        <Teleport to="body">
            <div id="loading-overlay">
                <div class="loading-icon"><div></div><div></div></div>
            </div>
        </Teleport>

    </div>
</template>

<script>
import { Teleport } from "vue";
import axios from "axios";
import Spinner from "./Spinner";
import 'regenerator-runtime/runtime'

export default {
    name: "PaProductFilters",
    components: {
        Spinner,
        Teleport
    },
    mounted() {
        jQuery("#product-filters-preload").remove();

        console.log("PaProductFilters mounted");
        this.getManufacturers();
        this.allStates["initial"](this);
    },
    data() {
        return {
            loading: {
                "endoscope-parts": false,
                "manufacturers": false,
                "series": false,
                "models": false,
                "subcategories": false,
                "endoscopy-accessories": false,
                "medical-devices": false,
                "equipment": false,
                "manufacturer-part-number": false,
            },
            currentState: 'endoscope-parts',
            categoryStates: [
                {
                    id: 'endoscope-parts',
                    name: 'endoscope-parts',
                    value: 'endoscope-parts',
                    text: 'Endoscope Repair Parts'
                },
                {
                    id: 'endoscopy-accessories',
                    name: 'endoscopy-accessories',
                    value: 'endoscopy-accessories',
                    text: 'Accessories'
                },
                {
                    id: 'medical-devices',
                    name: 'medical-devices',
                    value: 'medical-devices',
                    text: 'Medical Devices'
                },
                // {
                //     id: 'equipment',
                //     name: 'equipment',
                //     value: 'equipment',
                //     text: 'Equipment'
                // },
                {
                    id: 'endoscopy-equipment-parts-endocopes-equipment',
                    name: 'endoscopy-equipment-parts-endocopes-equipment',
                    value: 'endoscopy-equipment-parts-endocopes-equipment',
                    text: 'Equipment Parts'
                }
            ],
            allStates: {
                "initial": function (that) {

                    // do we have the manufacturer selected already?
                    // do we have the series selected already?
                    // do we have the model selected already?

                    that.filterSelectClass = 'three-selects';
                    that.filters['product-category'] = 'endoscope-parts';
                    console.log("initial && endoscope-repair-parts state");

                    that.categorySelects = {
                        "manufacturer": {
                            disabled: false,
                            visible: true,
                            items: []
                        },
                        "series": {
                            disabled: that.filters.manufacturer.length === 0,
                            visible: true,
                            items: []
                        },
                        "models": {
                            disabled: that.filters.series.length === 0,
                            visible: true,
                            items: []
                        },
                    };

                },
                "endoscope-parts": function (that) {

                    that.filterSelectClass = 'three-selects';

                    that.categorySelects = {
                        "manufacturer": {
                            disabled: false,
                            visible: true,
                            items: []
                        },
                        "series": {
                            disabled: that.filters.manufacturer.length === 0,
                            visible: true,
                            items: []
                        },
                        "models": {
                            disabled: that.filters.series.length === 0,
                            visible: true,
                            items: []
                        },
                    };

                },
                "endoscopy-accessories": (that) => {
                    console.log("endoscopy-accessories");

                    that.filterSelectClass = 'two-selects';
                    that.filters['product-category'] = 'endoscopy-accessories';

                    that.categorySelects = {
                        "manufacturer": {
                            disabled: false,
                            visible: true,
                            items: []
                        },
                        "subcategories": {
                            disabled: that.filters.manufacturer.length === 0,
                            visible: true,
                            items: []
                        },
                    };

                },
                "medical-devices": (that) => {
                    // enable and show manufacturers

                    that.filterSelectClass = 'three-selects';
                    that.filters['product-category'] = 'medical-devices';

                    that.categorySelects = {
                        "manufacturer": {
                            disabled: false,
                            visible: true,
                            items: []
                        },
                        "series": {
                            disabled: that.filters.manufacturer.length === 0,
                            visible: true,
                            items: []
                        },
                        "models": {
                            disabled: that.filters.series.length === 0,
                            visible: true,
                            items: []
                        },
                    };
                },
                // "equipment": (that) => {
                //     console.log("equipment");
                //
                //     that.filterSelectClass = 'two-selects';
                //     that.filters['product-category'] = 'equipment';
                //
                //     that.categorySelects = {
                //         "manufacturer": {
                //             disabled: false,
                //             visible: true,
                //             items: []
                //         },
                //         "manufacturer-part-number": {
                //             disabled: that.filters.manufacturer.length === 0,
                //             visible: true,
                //             items: []
                //         },
                //     };
                // },
                "endoscopy-equipment-parts-endocopes-equipment": (that) => {
                    console.log("endoscopy-equipment-parts-endocopes-equipment");

                    that.filterSelectClass = 'two-selects';
                    that.filters['product-category'] = 'endoscopy-equipment-parts-endocopes-equipment';

                    that.categorySelects = {
                        "manufacturer": {
                            disabled: false,
                            visible: true,
                            items: []
                        },
                        "manufacturer-part-number": {
                            disabled: that.filters.manufacturer.length === 0,
                            visible: true,
                            items: []
                        },
                    };
                },
            },
            categorySelects: {
                "manufacturer": {
                    disabled: false,
                    visible: true,
                    items: []
                },
                "series": {
                    disabled: true,
                    visible: true,
                    items: []
                },
                "models": {
                    disabled: true,
                    visible: true,
                    items: []
                },
                "subcategories": {
                    disabled: false,
                    visible: false,
                    items: []
                },
                "manufacturer-part-number": {
                    disabled: false,
                    visible: false,
                    items: []
                },
            },
            filters: {
                'product-category': 'endoscope-parts',
                manufacturer: '',
                series: '',
                models: '',
                subcategories: '',
                "manufacturer-part-number": ''
            },
            action: '',
            filterSelectClass: 'three-selects',
            showLoader: true,
            timeoutAfter: 30000,
        }
    },
    methods: {
        debug(val) {
            console.log(`value: ${val}`);
            return true;
        },
        displayLoading() {
            jQuery("#loading-overlay").show();
        },
        hideLoading() {
            jQuery("#loading-overlay").hide();
        },
        getFilterUrl() {
            return new Promise(async (resolve, reject) => {
                let formData = new FormData();

                formData.append('product-category', this.filters['product-category']);
                formData.append('manufacturer', this.filters.manufacturer);
                formData.append('series', this.filters.series);
                formData.append('models', this.filters.models);
                formData.append('subcategories', this.filters.subcategories);
                formData.append('manufacturer-part-number', this.filters['manufacturer-part-number']);

                const url = '/wp-json/pa-product-filters/v1/build-url';

                try {
                    this.showLoader = true;

                    const response = await axios.post(url, formData,{
                        timeout: this.timeoutAfter,
                    });

                    if (response.data.success && response.data.hasOwnProperty('url')) {
                        resolve(response.data.url);
                    } else {
                        reject();
                    }

                } catch (e) {
                    console.error(e);
                } finally {
                    this.showLoader = false;
                }
            });
        },
        filterProducts: async function () {
            this.displayLoading();

            let url = await this.getFilterUrl();

            location.href = url
        },
        async getManufacturers() {

            /*
             * Endoscope Repair Parts (Most Popular - Default) Main  (Category, Woocommerce product_category)
             *  Manufacturer
             *  Series
             *  Model (optional)
             *
             * POST
             * product-category=endoscope-repair-parts&filters=product-category&manufacturer=$man
             *
            */

            let formData = new FormData();
            formData.append('product-category', this.filters['product-category']);
            formData.append('filters', 'manufacturer');

            const url = '/wp-json/pa-product-filters/v1/filters';

            try {

                // this.showLoader = true;

                this.loading['manufacturers'] = true;

                const response = await axios.post(url, formData, {
                    timeout: this.timeoutAfter,
                });

                if (response.data.success) {
                    this.categorySelects.manufacturer.items = response.data.data;
                    this.loading['manufacturers'] = false;
                    console.log(response);
                } else {
                    console.log('we go some data');
                }

            } catch (e) {
                console.error(e);
            } finally {
                //   this.showLoader = false;
            }

        },
        async getSeries() {

            /*
             * Endoscope Repair Parts (Most Popular - Default) Main  (Category, Woocommerce product_category)
             *  Manufacturer
             *  Series
             *  Model (optional)
             *
             * POST
             * product-category=endoscope-repair-parts&filters=manufacturer&manufacturer=$man
             *
             */

            let formData = new FormData();

            formData.append('product-category', this.filters['product-category']);
            formData.append('filters', 'manufacturer');
            formData.append('manufacturer', this.filters.manufacturer);

            const url = '/wp-json/pa-product-filters/v1/filters';

            try {

                this.showLoader = true;

                this.categorySelects.series.disabled = true;
                this.categorySelects.series.items = [];
                this.loading['series'] = true;

                const response = await axios.post(url, formData,{
                    timeout: this.timeoutAfter,
                });

                if (response.data.success) {
                    this.loading['series'] = false;
                    this.categorySelects.series.items = response.data.data;
                    this.categorySelects.series.disabled = false;
                    this.showLoader = false;
                    console.log(response);
                } else {
                    console.log('we go some data');
                }

            } catch (e) {
                console.error(e);
            } finally {
                this.showLoader = false;
            }

        },
        async getModels() {

            /*
             * Endoscope Repair Parts (Most Popular - Default) Main  (Category, Woocommerce product_category)
             *  Manufacturer
             *  Series
             *  Model (optional)
             *
             * POST
             * product-category=endoscope-repair-parts&filters=manufacturer,series&manufacturer=$man&series=$ser
             *
             */

            let formData = new FormData();
            formData.append('product-category', this.filters['product-category']);
            formData.append('filters', 'manufacturer, series');
            formData.append('manufacturer', this.filters.manufacturer);
            formData.append('series', this.filters.series);

            const url = '/wp-json/pa-product-filters/v1/filters';

            try {
                this.showLoader = true;
                this.categorySelects.models.disabled = true;
                this.categorySelects.models.items = [];
                this.loading['models'] = true;

                const response = await axios.post(url, formData,{
                    timeout: this.timeoutAfter,
                });

                if (response.data.success) {
                    this.loading['models'] = false;
                    this.categorySelects.models.items = response.data.data;
                    this.categorySelects.models.disabled = false;
                    this.showLoader = false;
                    console.log(response);
                } else {
                    console.log('we go some data');
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.showLoader = false;
            }

        },
        async getSubcategories() {
            let formData = new FormData();

            formData.append('product-category', this.filters['product-category']);
            formData.append('filters', 'manufacturer');
            formData.append('manufacturer', this.filters.manufacturer);

            const url = '/wp-json/pa-product-filters/v1/filters';

            try {

                this.showLoader = true;

                this.categorySelects.subcategories.disabled = true;
                this.categorySelects.subcategories.items = [];
                this.loading['subcategories'] = true;

                const response = await axios.post(url, formData,{
                    timeout: this.timeoutAfter,
                });

                if (response.data.success) {
                    this.loading['subcategories'] = false;
                    this.categorySelects.subcategories.items = response.data.data;
                    this.categorySelects.subcategories.disabled = false;
                    this.showLoader = false;
                    console.log(response);
                } else {
                    console.log('we go some data');
                }

            } catch (e) {
                console.error(e);
            } finally {
                this.showLoader = false;
            }
        },
        async getManufacturerPartNumbers() {
            let formData = new FormData();
            formData.append('product-category', this.filters['product-category']);
            formData.append('filters', 'manufacturer');
            formData.append('manufacturer', this.filters.manufacturer);

            const url = '/wp-json/pa-product-filters/v1/filters';

            try {
                this.showLoader = true;

                this.categorySelects['manufacturer-part-number'].disabled = true;
                this.categorySelects['manufacturer-part-number'].items = [];
                this.loading['manufacturer-part-number'] = true;

                const response = await axios.post(url, formData, {
                    timeout: this.timeoutAfter,
                });

                if (response.data.success) {
                    this.loading['manufacturer-part-number'] = false;
                    this.categorySelects['manufacturer-part-number'].items = response.data.data;
                    this.categorySelects['manufacturer-part-number'].disabled = false;
                    this.showLoader = false;
                    console.log(response);
                } else {
                    console.log('we go some data');
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.showLoader = false;
            }

        },
        onCategorySelect(index) {

            this.currentState = this.categoryStates[index].id

            // reset on category change

            this.filters = {
                'product-category': this.currentState,
                manufacturer: '',
                series: '',
                models: '',
                subcategories: '',
                "manufacturer-part-number": ''
            };

            this.onStateChange(this.categoryStates[index].id)
        },
        checkEndoscopeRepairParts(selectedTargetName, value) {

            // should implement state machine method for currentStates
            if (selectedTargetName === 'manufacturer') {
                if (value.length === 0) {
                    this.categorySelects.series.disabled = true;
                    this.categorySelects.models.disabled = true;
                } else {
                    // okay we got a manufacturer
                    // so let's get our series
                    this.getSeries();
                }
            } else if (selectedTargetName === 'series') {
                if (value.length === 0) {
                    this.categorySelects.models.disabled = true;
                } else {
                    // okay we got a series
                    // so let's get our models
                    this.getModels();
                }
            }
        },
        checkEndoscopes(selectedTargetName, value) {
            this.categorySelects.series.disabled = this.filters.manufacturer.length === 0;
            this.categorySelects.models.disabled = this.filters.series.length === 0;
            // should implement state machine method for currentStates
            if (selectedTargetName === 'manufacturer') {
                if (value.length === 0) {
                    this.categorySelects.series.disabled = true;
                    this.categorySelects.models.disabled = true;
                } else {
                    // okay we got a manufacturer
                    // so let's get our series
                    this.getSeries();
                }
            } else if (selectedTargetName === 'series') {
                if (value.length === 0) {
                    this.categorySelects.models.disabled = true;
                } else {
                    // okay we got a series
                    // so let's get our models
                    this.getModels();
                }
            }
        },
        checkAccessories(selectedTargetName, value) {
            this.categorySelects.subcategories.disabled = this.filters.manufacturer.length === 0;
            // should implement state machine method for currentStates
            if (selectedTargetName === 'manufacturer') {
                if (value.length === 0) {
                    this.categorySelects.subcategories.disabled = true;
                } else {
                    this.getSubcategories();
                }
            }
        },
        checkEquipment(selectedTargetName, value) {
            this.categorySelects["manufacturer-part-number"].disabled = this.filters.manufacturer.length === 0;
            // should implement state machine method for currentStates
            if (selectedTargetName === 'manufacturer' && value.length === 0) {
                if (value.length === 0) {
                    this.categorySelects["manufacturer-part-number"].disabled = true;
                }
            }
        },
        checkManufacturerPartNumbers(selectedTargetName, value) {
            this.categorySelects["manufacturer-part-number"].disabled = this.filters.manufacturer.length === 0;

            // should implement state machine method for currentStates
            if (selectedTargetName === 'manufacturer') {
                if (value.length === 0) {
                    this.categorySelects["manufacturer-part-number"].disabled = true;
                } else {
                    this.getManufacturerPartNumbers();
                }
            }
        },
        onSelectChange(e, selectedTargetName) {
            const value = e.target.value;

            console.log({selectedTargetName, value});

            if (this.currentState === 'endoscope-parts') {
                this.checkEndoscopeRepairParts(selectedTargetName, value);
            } else if (this.currentState === 'endoscopy-accessories') {
                this.checkAccessories(selectedTargetName, value);
            } else if (this.currentState === 'medical-devices') {
                this.checkEndoscopes(selectedTargetName, value);
            } else if (this.currentState === 'equipment') {
                this.checkEquipment(selectedTargetName, value);
            } else if (this.currentState === 'endoscopy-equipment-parts-endocopes-equipment') {
                this.checkManufacturerPartNumbers(selectedTargetName, value);
            } else {
                console.log('no state selected');
            }
        },
        onStateChange(newState) {
            this.currentState = newState;
            this.allStates[newState](this);
            this.getManufacturers();
        },
        selectedLabel(name) {

            if (name === 'models') {
                name = name + ' (optional)';
            } else if (name === 'series') {
                name = name + ' (optional)';
            }

            return name.replace(/-/g, ' ').split(' ').map((word) => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }).join(' ');
        }
    },
}
</script>
